import React, { useContext } from "react";
import { Row, Col, Input, Button } from "reactstrap";
import { MainContext } from "context/mainContext";
import PanelCard from "components/Cards/PanelCard";

const CustomThirdParty = ({
  workSpaceInfo,
  onClick,
  list,
  table,
  selectedClient,
  firmDetails,
  ...props
}) => {
  const { textColor, bgColor } = useContext(MainContext);
  // console.log('workSpaceInfo', workSpaceInfo)
  return (
    <PanelCard headerName={props.headerName}>
      <Row>
        <Col className="mt-0 mb-sm-3">
          {props.loading ? null : workSpaceInfo === "Not available" ? null : props.userDetails.User.UserAccess >= 6 ? null : (
            <>
            <Button
              className="btn-icon btn-3 "
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`,
              }}
              type="button"
              size="sm"
              onClick={props.loading ? null : onClick}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
              <span className="btn-inner--text">Add Integration</span>
              {/* <span className="btn-inner--text">Add 3rd Party</span> */}
            </Button>
              {
                firmDetails?.Database === "DB" &&
                <Button
                  className="btn-icon btn-3 "
                  color="primary"
                  outline
                  type="button"
                  size="sm"
                  // style={{ display: 'none' }} 
                  onClick={props.loading ? null : props.toggleSchedulingModal}
                >
                  <span className="btn-inner--text">Client Integration Schedule Setting</span>
                  {/* <span className="btn-inner--text">Add 3rd Party</span> */}
                </Button>
              }
            </>
          )}
        </Col>
        <Col lg="4" sm="4" className="mb-3 mt-1">
          <Input
            placeholder="Search"
            type="text"
            bsSize="sm"
            onChange={props.handleSearch}
          />
        </Col>
      </Row>
      <Row style={{ flexDirection: "column" }}>
        {props.loading ? null : workSpaceInfo === "Not available" ? null : (
          <>
            {/* <Col className="d-flex flex-column flex-sm-row">
                    <h1 className="h4 mr-2">Workspace ID:</h1>
                    <p style={{ fontSize: '14px' }} className="text-justify">
                      {workSpaceInfo}
                    </p>
                  </Col> */}
            <Col className="d-flex flex-column flex-sm-row">
              <h1 className="h4 mr-2">Note:</h1>

              <p style={{ fontSize: "14px" }} className="text-justify">
                An email will be sent once the integration is done
              </p>
            </Col>
            <Col className="d-flex flex-column flex-sm-row">
              <h1 className="h4 mr-2">Client ID:</h1>

              <p style={{ fontSize: "14px" }} className="text-justify">
                {`${firmDetails?.Code.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy
                  ? selectedClient?.Details?.Legacy?.id
                  : selectedClient?.id
                  }`}
              </p>
            </Col>
          </>
        )}
      </Row>
      {/* <Row>{table}</Row> */}
      <Row>{list}</Row>

    </PanelCard>
  );
};

export default CustomThirdParty;

export const CustomList = ({ ...props }) => {
  <Col
    className="d-flex  mt-3 p-2 text-center "
    lg="6"
    style={{ flexDirection: "column", cursor: "pointer" }}
    key={props.i}
  >
    <Row className="justify-content-space-between p-3  ">
      <Col
        className="text-left border d-flex p-3 shadow--hover rounded"
        onClick={props.onClick}
      >
        <img
          alt="..."
          className="rounded-circle img-fluid shadow shadow-lg--hover"
          src={props.src}
          style={{ width: "40px", height: "40px", objectFit: "cover" }}
        />
        <h5 className="m-auto h5" style={{ fontSize: "14px" }}>
          {props.name}
          <br />
          {props.span}
        </h5>
        {props.icon}
      </Col>
    </Row>
  </Col>;
};
