import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Row, Table, Col, ModalFooter } from 'reactstrap';
import Modal from 'react-modal';
import CustomTooltip from '../../../customComponents/customComponents';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Papa from 'papaparse';
import { CsvCustomeHeaderCheck } from '../../../utils/utilityFunctions';
import { uploadBlob, pushToQueue } from 'service/AzureBlobService';
import ReactPlayer from "react-player";
import { ClipLoader, SyncLoader, BeatLoader } from "react-spinners";

import { businessTZ } from "utils/utilityFunctions";
import { MainContext } from 'context/mainContext';
const momentTZ = require("moment-timezone");
const RefreshHistoricRecordsModal = ({ onClose, isOpen, firmDetails, selectedClient, integrationDetails, ...props }) => {

  const [currentRefreshingData, setCurrentRefreshingData] = useState([])
  const [currentFormattedRefreshData, setCurrentFormattedRefreshData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [subParentVisibility, setSubParentVisibility] = useState({});
  const [parentVisibility, setParentVisibility] = useState({});
  const {
    textColor,
    bgColor,
  } = useContext(MainContext);

  useEffect(async () => {
    try {
      setIsLoading(true)
      let clientIdentity = `${firmDetails?.Code.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy ? selectedClient?.Details?.Legacy?.id : selectedClient?.id}`
      const refreshData = await fetch(`/api/clientService/historicRefreshData?orgId=${integrationDetails?.id}&businessId=${firmDetails?.id}&clientId=${selectedClient?.id}&clientIdentity=${clientIdentity}&service=${integrationDetails?.Service?.id}&dbDestination=${integrationDetails?.Details?.dbDestination}`)
      const refreshDataResult = await refreshData.json()
      setCurrentRefreshingData(refreshDataResult.data)
      console.log(refreshDataResult.data)
      const data = refreshDataResult.data
      const newData = processGroupings(data)
      processJobStartEndDate(newData)
      setCurrentFormattedRefreshData(newData)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setCurrentRefreshingData([])
    }
  }, [])

  
  const toggleVisibility = (label, setState) => {
    setState((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label]
    }));
  };

  const toggleSubParentVisibility = (label) => {
    toggleVisibility(label, setSubParentVisibility);
  };

  const toggleParentVisibility = (label) => {
    toggleVisibility(label, setParentVisibility);
  };

  function processJobStartEndDate(newData)
  {
    newData.forEach(item => {
      const { earliestDateTriggered, oldestLastRefreshDate } = item.children.reduce(
        (acc, child) => {
          // Update the earliest dateTriggered
          if (!acc.earliestDateTriggered || new Date(child.dateTriggered) < new Date(acc.earliestDateTriggered)) {
            acc.earliestDateTriggered = child.dateTriggered;
          }
      
          // Update the oldest lastRefreshDate
          if (!acc.oldestLastRefreshDate || new Date(child.updatedAt) > new Date(acc.oldestLastRefreshDate)) {
            acc.oldestLastRefreshDate = child.updatedAt;
          }
      
          return acc;
        },
        { earliestDateTriggered: null, oldestLastRefreshDate: null }
      );

      item.refreshTriggerType = item.children[0].refreshTriggerType
      item.dateTriggered = earliestDateTriggered
      item.updatedAt = oldestLastRefreshDate
      item.refreshStatus = item.children[0].refreshStatus

    })
  }

  function processGroupings(data)
  {
    const sortedData = data.sort((a, b) => {
      return parseInt(b.jobRunID) - parseInt(a.jobRunID);
    });

    const parent = {}
    const subParent = {}
    const grouped = {};
    const newData = []
    sortedData.forEach(item => {
      const { jobRunID, tableName, ...itemWithoutDbDestination } = item;

      let db = newData.find(item => item.jobRunID === jobRunID)

      if(!grouped[jobRunID]) {
 
        parent[jobRunID] = false
        grouped[jobRunID] = { jobRunID: jobRunID, children: []}
        newData.push({ 
          jobRunID: jobRunID, 
          children: [ 
            { 
              tableName, 
              ...itemWithoutDbDestination,
              children: [ itemWithoutDbDestination ] 
            }
          ]
        })
      } else {
        const tableItem = { tableName, ...itemWithoutDbDestination, children: [ itemWithoutDbDestination ]}
        db?.children.push(tableItem)
      }

      // let table = db?.children.find(table => table.tableName === item.tableName)
      
      // if(!table) {
      //   const tableItem = { tableName, children: [ itemWithoutDbDestination ]}
      //   grouped[jobRunID].children.push(tableItem)
      //   db?.children.push(tableItem)
      //   subParent[`${jobRunID}-${tableName}`] = true
      // } else {
      //   table.children.push(itemWithoutDbDestination)
      // }
    });
    // console.log(parent)
    // console.log(subParent)
    // setSubParentVisibility(subParent)
    setParentVisibility(parent)
    return newData
  }

  const FormatedTableList = useCallback(({ currentFormattedRefreshData }) => {
    return (
      <tbody>
        {currentFormattedRefreshData && currentFormattedRefreshData.length > 0 && currentFormattedRefreshData.map((dbD, index) => (
          <>
            <tr key={index}>
              <td 
                colSpan={!parentVisibility?.[dbD.jobRunID] ? 2 : 9} 
                onClick={() => toggleParentVisibility(dbD.jobRunID)}
              >
                <i className={`ni ${parentVisibility?.[dbD.jobRunID] ? 'ni-bold-down' : 'ni-bold-right'} mr-1 mt-1`} style={{ }} /> 
                Job ID: {dbD.jobRunID}
              </td>
              {!parentVisibility?.[dbD.jobRunID] &&
                <>
                  {/* <td onClick={() => toggleSubParentVisibility(`${dbD.jobRunID}-${dbD.children[0].tableName}`)}>  
                    <i className={`ni ${!subParentVisibility?.[`${dbD.jobRunID}-${dbD.children[0].tableName}`] ? 'ni-bold-down' : 'ni-bold-right'} mr-1 mt-1`} /> 
                    {dbD.children[0].tableName}
                  </td>
                  <TDDetails data={dbD.children[0].children[0]} /> */}
                   <TDDetails data={dbD} />
                </>
              }
              {/* {index !== 0 && <EmptyTd count={3} />} */}
            </tr>
            
            {parentVisibility?.[dbD.jobRunID] && dbD.children && dbD.children.length > 0 && dbD.children.map((tableName, index) => (
              <>
                {
                // index !== 99 &&
                  <tr key={index} style={{ background: '#f7fcff' }}>
                    {/* <td></td> */}
                    <td colSpan={2} style={{ paddingLeft: 20 }} onClick={() => toggleSubParentVisibility(`${dbD.jobRunID}-${tableName.tableName}`)}>
                      {/* <i className={`ni ${!subParentVisibility?.[`${dbD.jobRunID}-${tableName.tableName}`] ? 'ni-bold-down' : 'ni-bold-right'} mr-1 mt-1`} />  */}
                       {tableName.tableName}
                    </td>
                    {/* {index !== 0 && <EmptyTd count={3} />} */}
                    <TDDetails data={tableName.children[0]} />
                  </tr>
                }

                {/* {tableName.children && tableName.children.length > 0 && tableName.children.map((child, index) => (
                  index !== 0 &&
                  <tr key={index} style={{ background: '#f9f9f9' }}>
                    <EmptyTd count={2} />
                    <td colSpan={2}></td>
                    <TDDetails data={child} />
                  </tr>
                ))} */}
              </>
            ))}
          </>
        ))}
      </tbody>
    )
  }, [currentFormattedRefreshData, subParentVisibility, parentVisibility])
  const TDDetails = ({data}) => {
    return (
      <>
        <td style={{ width: 27 }}>
          {data.refreshStatus === "Completed" ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-warning" />}
        </td>
        <td>{data.refreshTriggerType}</td>
        <td>{FormattedDate(data.dateTriggered)}</td>
        <td>{FormattedDate(data.updatedAt)}</td>
        <td>{getDuration(data.updatedAt, data.dateTriggered)}</td>
        <td>{data.refreshStatus}</td>
        <td>{data.refreshDetails}</td>
      </>
    )
  }
  const EmptyTd = ({ count }) => {
    return (
      Array.from({ length: count }).map((_, i) => (
        <td key={i}></td>
      ))
    )
  }
  const FormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const readableDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'UTC'
    });

    return readableDate.replace(" at ", ", ")
  }
  const getDuration = (startDate, endDate) => {
    const createdDate = new Date(startDate);
    const updatedDate = new Date(endDate);

    const differenceInMilliseconds = createdDate - updatedDate;

    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    const duration = `${hours > 0 ? hours + ' hours, ' : '' } ${minutes > 0 ? minutes + ' minutes, ' : ''} ${seconds} seconds`;

    return duration
  }
  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: '80%',
            height: '80%'
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose()
        }
        }
        contentLabel="Upload CSV Modal"
      >
        <Row className="lg-12 pl-3">
          <h2>Last Refresh History</h2>
        </Row>
        <div className='CSVList-Container'>
          {
            isLoading ?
              <>
                Loading<BeatLoader size={3} />
              </>
              :
              currentRefreshingData.length < 1 ?
                <>
                  <p className="text-center mt-3">No data found.</p>
                </>
                :
                <Table className='CSVHistoryList'>
                  <thead style={{ position: 'sticky', zIndex: 100, top: -1, background: bgColor, color: textColor }}>
                    <tr>
                      {/* <td style={{ fontWeight: "bold" }}>Table Name</td>
                      <td style={{ fontWeight: "bold" }}>Type</td>
                      <td style={{ fontWeight: "bold" }}>Date Initiated</td>
                      <td style={{ fontWeight: "bold" }}>Last Refresh Date</td> */}
                      
                      <td style={{ fontWeight: "bold" }}>Job ID</td>
                      <td style={{ fontWeight: "bold" }}>Table Name</td>
                      <td style={{ fontWeight: "bold" }}></td>
                      <td style={{ fontWeight: "bold" }}>Trigger Type</td>
                      <td style={{ fontWeight: "bold" }}>Start Date/Time</td>
                      <td style={{ fontWeight: "bold" }}>Completed Data/Time</td>
                      <td style={{ fontWeight: "bold" }}>Duration</td>
                      <td style={{ fontWeight: "bold" }}>Status</td>
                      <td style={{ fontWeight: "bold" }}>Details</td>
                    </tr>
                  </thead>
                  {/* <tbody>
                    {
                      currentRefreshingData.length > 0 && currentRefreshingData.map((data, i) => (
                        <tr key={i}>
                          <td>{data.tableName}</td>
                          <td>{data.dbDestination}</td>
                          <td>{firmDetails.TimeZone ? momentTZ.tz(data.dateTriggered) : businessTZ(firmDetails.CountryCode, data.dateTriggered, true)}</td>
                          <td>{firmDetails.TimeZone ? momentTZ.tz(data.lastRefreshDate) : businessTZ(firmDetails.CountryCode, data.lastRefreshDate, true)}</td>
                        </tr>
                      ))
                    }

                  </tbody> */}
                  <FormatedTableList 
                    currentFormattedRefreshData={currentFormattedRefreshData} 
                  />
                </Table>
          }
        </div>
        <ModalFooter className='pt-2 pb-0 pr-0'>
          <Button onClick={() => {
            onClose()
          }}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RefreshHistoricRecordsModal;