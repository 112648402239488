import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Table, Col, ModalFooter } from 'reactstrap';
import Modal from 'react-modal';
import CustomTooltip from '../../../customComponents/customComponents';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Papa from 'papaparse';
import { CsvCustomeHeaderCheck } from '../../../utils/utilityFunctions';
import { uploadBlob, pushToQueue } from 'service/AzureBlobService';
import ReactPlayer from "react-player";
import { ClipLoader, SyncLoader, BeatLoader } from "react-spinners";
import { businessTZ } from "utils/utilityFunctions";

const momentTZ = require("moment-timezone");
const RefreshRecordsModal = ({ onClose, isOpen, firmDetails, selectedClient, integrationDetails, ...props }) => {
  const [currentRefreshingData, setCurrentRefreshingData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(async () => {
    try {
      setIsLoading(true)
      let clientIdentity = `${firmDetails?.Code.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy ? selectedClient?.Details?.Legacy?.id : selectedClient?.id}`
      const refreshData = await fetch(`/api/clientService/getRefreshData?orgId=${integrationDetails?.id}&businessId=${firmDetails?.id}&clientId=${selectedClient?.id}&clientIdentity=${clientIdentity}&service=${integrationDetails?.Service?.id}&dbDestination=${integrationDetails?.Details?.dbDestination}`)
      const refreshDataResult = await refreshData.json()
      setCurrentRefreshingData(refreshDataResult.data)
      // console.log(refreshDataResult)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setCurrentRefreshingData([])
    }
  }, [])

  useEffect(() => {
    // console.log(firmDetails)
    // console.log(selectedClient)
    // console.log(integrationDetails)
    if (!isLoading) {
      if (integrationDetails.Task[0].type === "refresh" && integrationDetails.Task[0].status !== "completed") {
        let clientIdentity = `${firmDetails?.Code.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy ? selectedClient?.Details?.Legacy?.id : selectedClient?.id}`
        // if(accountingFirm?.Code === "BIGOLESHOECOMPANY" && (clientID === "BIGOLESHOECOMPANY_CLIENT1846" || clientID === "BIGOLESHOECOMPANY_CLIENT1644" || clientID === "BIGOLESHOECOMPANY_CLIENT1518")){
        // Create a new EventSource instance and connect to the SSE endpoint
        const eventSource = new EventSource(`https://evamobileservices.azurewebsites.net/api/refreshData?orgId=${integrationDetails?.id}&businessId=${firmDetails?.id}&clientId=${selectedClient?.id}&clientIdentity=${clientIdentity}&service=${integrationDetails?.Service?.id}&dbDestination=${integrationDetails?.Details?.dbDestination}`);
        // const eventSource = new EventSource(`http://192.168.1.16:4000/api/refreshData?orgId=${integrationDetails?.id}&businessId=${firmDetails?.id}&clientId=${selectedClient?.id}&clientIdentity=${clientIdentity}&service=${integrationDetails?.Service?.id}&dbDestination=${integrationDetails?.Details?.dbDestination}`);

        // eventSource.onopen = (e) => console.log(">>> Connection opened!", e);
        // Listen for messages from the server
        eventSource.onmessage = (event) => {
          // Parse the JSON data from the event
          let sseResult = JSON.parse(event.data)
          if (sseResult.result) {
            // console.log('sseResult', sseResult)
            setCurrentRefreshingData(sseResult.result)
          }
        };

        // Handle any errors that occur
        eventSource.onerror = (error) => {
          console.error('Error with SSE:', error);
          // eventSource.close(); // Optionally close the connection on error
        };

        // Cleanup when the component unmounts
        return () => {
          eventSource.close();
        };
        // }
      }
    }
  }, [])

  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: '930px',
            height: '520px'
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose()
        }
        }
        contentLabel="Upload CSV Modal"
      >
        <Row className="lg-12 pl-3">
          <h2>Refresh Data</h2>
        </Row>
        <div className='CSVList-Container'>
          {
            isLoading ?
              <>
                Loading<BeatLoader size={3} />
              </>
              :
              <Table className='CSVHistoryList'>
                <thead>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Table Name</td>
                    <td style={{ fontWeight: "bold" }}>Type</td>
                    <td style={{ fontWeight: "bold" }}>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    currentRefreshingData.length > 0 && currentRefreshingData.map((data, i) => (
                      <tr key={i}>
                        <td>{data.TableName}</td>
                        <td>{data.DatabaseDestination}</td>
                        <td>{data.RefreshStatus}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
          }
        </div>
        <ModalFooter className='pr-0'>
          <Button onClick={() => {
            onClose()
          }}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RefreshRecordsModal;