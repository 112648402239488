import XBImage from '../../../assets/img/XB.png';
import XPMImage from '../../../assets/img/XPM.png';
import VisualGraphView from 'components/DashboardVisual/VisualGraphView'
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2';
import DashboardTempaltemGraphView from './DashboardTempaltemGraphView';
import DashboardTemplateltemPieGraph from './DashboardTemplateltemPieGraph';
import DashboardTemplateItemCardView from './DashboardTemplateItemCardView';
import { registerDashboardTemplate } from 'services/mongoDB';
import { updateVisualPersonalDashboard } from 'services/mongoDB';
import DashboardTempaltemGridView from './DashboardTempaltemGridView';
import moment from "moment";
var momentTZ = require("moment-timezone");


function DashboardTemplateItemNewVisual({ isEVAPage = false, item, index, gridHeader, sqlresult, templateItemVisual, editMode, 
    onResizeItem, selectedDashboardTemplate, isFullScreen, selectedTemplate, selectedDashboard, onFullScreenOnly,
    theme, treeViewData, isWithChild, isShowGrid, toggleAll , isShowGrap, layouts, lastRefresh, firmDetails, groupLevel, integrationImg}) 
{
    const {selectedViewOption} = templateItemVisual
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [gridGroupLevel, setGridGroupLevel] = useState(null)

    return (
        <>
       
            <div style={{ display: 'block', width: '100%', height: 'calc(100% - 60px)', padding: '10px 5px', overflow: 'hidden', paddingBottom: 0 }}>
                {(selectedViewOption === 'Line Chart' || selectedViewOption === 'Bar Chart') &&
                    <DashboardTempaltemGraphView
                        item={item}
                        treeViewData={treeViewData}
                        sqlresult={sqlresult}
                        templateItemVisual={templateItemVisual}
                        selectedViewOption={selectedViewOption}
                        newUpdateCount={newUpdateCount}
                        groupLevel={groupLevel}
                        gridHeader={gridHeader}
                    />
                }
                {selectedViewOption === 'Pie Chart' &&
                    <DashboardTemplateltemPieGraph
                        item={item}
                        treeViewData={treeViewData}
                        sqlresult={sqlresult}
                        templateItemVisual={templateItemVisual}
                        selectedViewOption={selectedViewOption}
                        newUpdateCount={newUpdateCount}
                        groupLevel={groupLevel}
                    />
                }
                {selectedViewOption === 'Card' &&
                    <DashboardTemplateItemCardView
                        index={index}
                        item={item}
                        treeViewData={treeViewData}
                        sqlresult={sqlresult}
                        templateItemVisual={templateItemVisual}
                        selectedViewOption={selectedViewOption}
                        layouts={layouts}
                        onFullScreenOnly={onFullScreenOnly}
                        groupLevel={groupLevel}
                        gridHeader={gridHeader}
                    />
                }
                {(selectedViewOption === 'Grid' || !selectedViewOption)  &&
                    <DashboardTempaltemGridView
                        item={item}
                        gridHeader={gridHeader}
                        sqlresult={sqlresult}
                        theme={theme}
                        treeViewData={treeViewData}
                        isWithChild={isWithChild}
                        isShowGrid={isShowGrid}
                        toggleAll={toggleAll}
                        isShowGrap={isShowGrap}
                        groupLevel={groupLevel}
                    />
                }
                {!isEVAPage &&
                <>
                    <div className='lastRefreshDate'>
                        Last Refresh:{" "}
                        {lastRefresh
                        ? firmDetails.TimeZone
                            ? momentTZ.tz(lastRefresh, firmDetails.TimeZone).fromNow()
                            : moment(lastRefresh).fromNow()
                        : "No record found"}
                    </div>
                    {/* {item.integration && item.integration !== undefined && */}
                        <div className='dashboardItemIntegration'>
                            <img src={integrationImg} alt="Integration Logo" />
                            {/* {!fileTypeImages[item.integration] && <div className='etaniAIlabel'>Etani AI</div>} */}
                        </div>
                    {/* } */}
                </>
                }
            </div>
        </>
    )
}

export default DashboardTemplateItemNewVisual